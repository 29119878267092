import { css } from '@emotion/react';
import { FC } from 'react';

import { colors } from '../../theme/colors';
import { ButtonArrowIcon } from '../icons/ButtonArrowIcon';

type Props = {
  onClick?: () => void;
};

export const ArrowCircleButton: FC<Props> = ({ onClick }) => {
  return (
    <button
      css={ArrowCircleButtonStyle}
      onClick={onClick}
      className='arrowCircle'
    >
      <ButtonArrowIcon />
    </button>
  );
};

const ArrowCircleButtonStyle = css({
  width: 40,
  height: 40,
  display: 'grid',
  placeItems: 'center',
  background: colors.primary,
  borderRadius: 20,
  border: `2px solid ${colors.primary}`,
  transition: '0.3s',

  '& .arrowStroke': {
    stroke: colors.white,
  },
});
