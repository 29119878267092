import { css } from '@emotion/react';
import { FC } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { colors } from '../../theme/colors';

import { ArrowCircleButton } from '../atoms/ArrowCircleButton';

type Props = {
  node: {
    type?: string;
    slug?: string | null;
    title?: string | null;
    publishedAt?: string | null;
    metadata?: {
      readonly tags: readonly ({ readonly name: string } | null)[] | null;
    } | null;
    cover?: {
      readonly gatsbyImageData: IGatsbyImageData | null;
    } | null;
    image?: IGatsbyImageData;
    videoId?: string | null;
  };
  isLast?: boolean;
  isTop?: boolean;
  onClick?: () => void;
};

export const NewsCard: FC<Props> = ({
  node,
  isLast = false,
  isTop = false,
  onClick,
}) => {
  // 日付を切り出し
  const date = `${node.publishedAt?.slice(0, 4)}.${node.publishedAt?.slice(
    5,
    7
  )}.${node.publishedAt?.slice(8, 10)}`;

  // 文字数制限を付けたのタイトル
  const title =
    node.title && isTop && node.title.length > 28
      ? node.title.slice(0, 27) + '…'
      : node.title;

  return onClick ? (
    <div css={NewsCardStyle(isLast)} onClick={onClick}>
      <div css={ImageWrapperStyle}>
        <div css={ImageOverflowStyle}>
          <GatsbyImage
            image={node.cover?.gatsbyImageData! || node.image}
            alt={node.title!}
            css={ImageStyle}
            objectFit='cover'
            className='newsCardImage'
          />
        </div>
      </div>
      <div css={NewsCardInfoWrapperStyle}>
        {node.metadata?.tags && node.publishedAt && (
          <div css={NewsCardInfoWrapperTopStyle}>
            <p css={CardTagStyle}>{node.metadata?.tags[0]?.name}</p>
            <p css={PublishedAtStyle}>{date}</p>
          </div>
        )}
        <p css={TitleStyle} className='newsTitle'>
          {title}
        </p>
      </div>
      <div css={ButtonStyle}>
        <ArrowCircleButton />
      </div>
    </div>
  ) : (
    <Link to={`/${node.type}/${node.slug}/`} css={{ width: '100%' }}>
      <div css={NewsCardStyle(isLast)}>
        <div css={ImageWrapperStyle}>
          <div css={ImageOverflowStyle}>
            <GatsbyImage
              image={node.cover?.gatsbyImageData! || node.image}
              alt={node.title!}
              css={ImageStyle}
              objectFit='cover'
              className='newsCardImage'
            />
          </div>
        </div>
        <div css={NewsCardInfoWrapperStyle}>
          {node.metadata?.tags && node.publishedAt && (
            <div css={NewsCardInfoWrapperTopStyle}>
              <p css={CardTagStyle}>{node.metadata?.tags[0]?.name}</p>
              <p css={PublishedAtStyle}>{date}</p>
            </div>
          )}
          <p css={TitleStyle} className='newsTitle'>
            {title}
          </p>
        </div>
        <div css={ButtonStyle}>
          <ArrowCircleButton />
        </div>
      </div>
    </Link>
  );
};
const NewsCardStyle = (isLast: boolean) =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 24,
    width: '100%',
    padding: '20px 0',
    borderTop: `solid 1px ${colors.disabled}`,
    borderBottom: isLast ? `solid 1px ${colors.disabled}` : 'none',

    '@media(max-width: 768px)': {
      flexDirection: 'column',
    },

    '&:hover& .newsCardImage': {
      transform: 'scale(1.05, 1.05)',
    },

    '&:hover& .newsTitle': {
      color: colors.primary,
    },

    '&:hover& .arrowStroke': {
      stroke: colors.primary,
    },

    '&:hover& .arrowCircle': {
      background: colors.white,
    },
  });

const ImageStyle = css({
  width: 180,
  maxHeight: 120,
  borderRadius: 12,
  transition: '0.3s',

  '@media(max-width: 768px)': {
    width: '100%',
    maxWidth: 338,
    height: 'inherit',
    minHeight: 160,
    maxHeight: 226,
    margin: '0 auto',
  },
});

const ImageWrapperStyle = css({
  minWidth: 180,
  height: 120,
  display: 'flex',
  alignItems: 'center',

  '@media(max-width: 768px)': {
    width: '100%',
    maxWidth: 338,
    height: 'inherit',
    minHeight: 160,
    maxHeight: 226,
    margin: '0 auto',
  },
});

const ImageOverflowStyle = css({
  borderRadius: 12,
  overflow: 'hidden',
  isolation: 'isolate',
});

const NewsCardInfoWrapperStyle = css({
  flexGrow: 1,
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 20,
});

const NewsCardInfoWrapperTopStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
});

const TitleStyle = css({
  fontSize: 16,
  color: colors.black,
  lineHeight: 2,
  maxHeight: 64,
  overflow: 'hidden',
  position: 'relative',
  transition: '0.3s',

  '@media(max-width: 768px)': {
    fontSize: 14,
  },
});

const CardTagStyle = css({
  width: 'fit-content',
  fontSize: 12,
  border: `2px solid ${colors.primary}`,
  padding: '6px 10px',
  borderRadius: 24,
  color: colors.primary,
  lineHeight: 1,
});

const PublishedAtStyle = css({
  color: colors.gray,
  fontSize: 16,

  '@media(max-width: 768px)': {
    fontSize: 14,
  },
});

const ButtonStyle = css({
  display: 'flex',
  alignItems: 'center',
  '@media(max-width: 768px)': {
    display: 'none',
  },
});
