import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { graphql } from 'gatsby';

import { Pager } from '../../components/organisms/Pager';
import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { ArticleListHeading } from '../../components/organisms/ArticleListHeading';
import { TagFilter } from '../../components/organisms/TagFilter';
import { NewsCard } from '../../components/news/NewsCard';

export const Head = () => (
  <HeadInner
    path='/news/'
    title='お知らせ'
    description='paild（ペイルド）のお知らせです。お知らせ、アップデート、プレスリリースなど複数のカテゴリでまとめています'
  />
);

const NewsIndexPage: FC<{ data: GatsbyTypes.NewsIndexQueryQuery }> = (
  props
) => {
  const serviceUpdatePerPage = 10;
  const [serviceUpdatePageNumber, setServiceUpdatePageNumber] = useState(0);

  const handleServiceUpdatePageNumber = (index: number) => {
    setServiceUpdatePageNumber(index);
  };
  const [activeTagList, setActiveTagList] = useState<string[]>([]);

  const onClickTag = (tagName: string) => {
    setServiceUpdatePageNumber(0);
    setActiveTagList([tagName]);
  };

  const onClickAll = () => {
    setServiceUpdatePageNumber(0);
    setActiveTagList([]);
  };

  // リンク用にtypeフィールドを追加
  const allServiceUpdates = props.data.allContentfulServiceUpdate.nodes.map(
    (updates) => ({ ...updates, type: 'updates' })
  );

  const allPressRelease = props.data.allContentfulPressRelease.nodes.map(
    (press) => ({ ...press, type: 'press-release' })
  );

  const allInformations = props.data.allContentfulInformation.nodes.map(
    (info) => ({ ...info, type: 'info' })
  );

  const allUpdates = [
    ...allServiceUpdates,
    ...allPressRelease,
    ...allInformations,
  ].sort((a, b) => (a.publishedAt! > b.publishedAt! ? -1 : 1));

  const allUpdateNodes = allUpdates.filter((node) =>
    activeTagList.length
      ? activeTagList.some(
          (value) =>
            node.metadata?.tags && value === node.metadata?.tags[0]?.name
        )
      : node
  );

  const allTags = allUpdates.map((node) => {
    if (!node.metadata?.tags) return '';
    return node.metadata?.tags[0]?.name || '';
  });

  const tagCategoryList: { category: string; tagList: string[] }[] = [
    {
      category: '種別',
      tagList: Array.from(new Set(allTags)),
    },
  ];

  const links = [{ name: 'お知らせ', path: '/news/' }];

  return (
    <Layout links={links}>
      <ArticleListHeading
        category='news'
        title='お知らせ'
        description={['paildからのお知らせです。']}
      />
      <TagFilter
        tagCategoryList={tagCategoryList}
        activeTagList={activeTagList}
        onClickTag={onClickTag}
        onClickAll={onClickAll}
      />
      <section css={NewsListStyle}>
        {allUpdateNodes
          .slice(
            serviceUpdatePageNumber * serviceUpdatePerPage,
            serviceUpdatePageNumber * serviceUpdatePerPage +
              serviceUpdatePerPage
          )
          .map((update, index, array) => {
            return (
              <NewsCard
                key={index}
                node={update}
                isLast={index === array.length - 1}
              />
            );
          })}
      </section>
      <Pager
        pageNumber={serviceUpdatePageNumber}
        pageLength={Math.ceil(allUpdateNodes.length / serviceUpdatePerPage)}
        handleNumberChange={handleServiceUpdatePageNumber}
      />
    </Layout>
  );
};

const NewsListStyle = css({
  width: '100%',
  maxWidth: 1200,
  padding: '0 28px',
});

export const query = graphql`
  query NewsIndexQuery {
    allContentfulServiceUpdate(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        id
        slug
        cover {
          gatsbyImageData(placeholder: NONE)
        }
        description {
          description
        }
        title
        publishedAt
        metadata {
          tags {
            name
          }
        }
      }
    }
    allContentfulPressRelease(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        id
        slug
        cover {
          gatsbyImageData(placeholder: NONE)
        }
        description {
          description
        }
        title
        publishedAt
        metadata {
          tags {
            name
          }
        }
      }
    }
    allContentfulInformation {
      nodes {
        id
        slug
        cover {
          gatsbyImageData(placeholder: NONE)
        }
        description {
          description
        }
        title
        publishedAt
        metadata {
          tags {
            name
          }
        }
      }
    }
  }
`;

export default NewsIndexPage;
