import { css } from '@emotion/react';
import { FC } from 'react';
import { colors } from '../../theme/colors';

import { ButtonArrowIcon } from '../icons/ButtonArrowIcon';

interface Props {
  pageNumber: number;
  pageLength: number;
  handleNumberChange: (index: number) => void;
}

export const Pager: FC<Props> = ({
  pageNumber,
  pageLength,
  handleNumberChange,
}) => {
  const onButtonClick = (index: number) => {
    handleNumberChange(index);
    window.scrollTo(0, 0);
  };

  // 配列に入ってるインデックスのページネーションを表示、画面幅によって表示する数が違うので
  const isVisibleList = (length: number) => {
    if (pageLength < 4) return [0, 1, 2, 3];
    if (pageNumber <= 2)
      return [...Array(length)].map((_, index) => {
        return index;
      });
    if (pageLength - pageNumber <= 2)
      return [...Array(length)].map((_, index) => {
        return pageLength + index - length;
      });
    else {
      return [...Array(length)].map((_, index) => {
        return pageNumber + index - 2;
      });
    }
  };

  return (
    <div css={PaginationStyle}>
      {pageLength !== 1 && (
        <button
          disabled={pageNumber === 0}
          css={[
            ButtonStyle(false, pageNumber === 0),
            FirstPaginationStyle(pageNumber === 0),
          ]}
          onClick={() => onButtonClick(0)}
        >
          <ButtonArrowIcon />
        </button>
      )}
      {[...Array(pageLength)].map((_, index) => {
        return (
          <button
            css={[
              ButtonStyle(index === pageNumber, false),
              NumberPaginationStyle(isVisibleList, index),
            ]}
            onClick={() => onButtonClick(index)}
            key={index}
          >
            {index + 1}
          </button>
        );
      })}
      {pageLength !== 1 && (
        <button
          disabled={pageNumber === pageLength - 1}
          css={[
            ButtonStyle(false, pageNumber === pageLength - 1),
            LastPaginationStyle(pageNumber === pageLength - 1),
          ]}
          onClick={() => onButtonClick(pageLength - 1)}
        >
          <ButtonArrowIcon />
        </button>
      )}
    </div>
  );
};

const PaginationStyle = css({
  padding: '80px 0 120px',
  display: 'flex',
  gap: 12,

  '@media(max-width: 768px)': {
    gap: 8,
    padding: '60px 0',
  },
});

const FirstPaginationStyle = (disabled: boolean) =>
  css({
    display: 'grid',
    placeItems: 'center',
    transform: 'rotate(180deg)',
    pointerEvents: disabled ? 'none' : 'auto',
    '&:hover': {
      paddingLeft: 10,
    },
    '& .arrowStroke': {
      stroke: disabled ? colors.disabled : colors.primary,
    },
  });

const LastPaginationStyle = (disabled: boolean) =>
  css({
    display: 'grid',
    placeItems: 'center',
    pointerEvents: disabled ? 'none' : 'auto',
    '&:hover': {
      paddingLeft: 10,
    },
    '& .arrowStroke': {
      stroke: disabled ? colors.disabled : colors.primary,
    },
  });

const NumberPaginationStyle = (
  isVisibleList: (length: number) => number[],
  index: number
) =>
  css({
    display: isVisibleList(5).includes(index) ? 'grid' : 'none',
    placeItems: 'center',

    '@media(max-width: 768px)': {
      display: isVisibleList(4).includes(index) ? 'grid' : 'none',
    },
  });

const ButtonStyle = (isActive: boolean, disabled: boolean) =>
  css({
    width: 62,
    height: 62,
    borderRadius: '50%',
    border: `solid 2px ${disabled ? colors.disabled : colors.primary}`,

    background: isActive ? colors.primary : colors.white,
    color: isActive
      ? colors.white
      : disabled
      ? colors.disabled
      : colors.primary,
    transition: '0.3s',

    '&:hover': {
      background: colors.primary,
      color: colors.white,

      '& .arrowStroke': { stroke: colors.white },
    },

    '@media(max-width: 768px)': {
      width: 48,
      height: 48,
    },
  });
